<template>
    <div class="form-group" :class="{'hide': (!props.show)}">
        <label :for="inputId" class="contact-form-label">{{ labelText }}
			<span v-if="!props.isRequired" class="optional-text"> (optional)</span>
		</label>
        <div class="form-group-container">
            <VueDatePicker  :uid="inputId" 
                            v-model="selectedDate"
                            placeholder="yyyy/mm/dd"
                            :max-date="maxDateLimit"
                            :min-date="minDateLimit"
                            :enable-time-picker="false"
                            format="yyyy/MM/dd"
                            position="left"
                            text-input
                            @update:model-value="handleSetDate"
                            @closed="handleClose">
                <template #input-icon>
                    <div class="px-2">
                        <NuxtIcon name="gibs-calendar" class="text-6 text-japaneseindigo"></NuxtIcon>
                    </div>
                </template>
            </VueDatePicker>
        </div>
        <p :id="`${inputId}-error`" class="gibs-form-group-error gibs-small-regular"></p>
        <p class="hint-text">{{ hint }}</p>
    </div>
</template>

<!-- Documentation: https://vue3datepicker.com -->
<script setup lang="ts">
    import VueDatePicker  from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'

    const props = defineProps({
        inputId: {
            type: String,
            required: true,
        },
        labelText: {
            type: String,
            required: true,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        hint: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [Date, String],
            default: null,
        },
        validRange: {
            type: String,
            default: null,
        },
        validator: {
            type: Validation,
            default: null,
        },
        validatorRuleId: {
            type: String,
            default: null
        },
        show: {
            type: Boolean,
            default: true
        },
    });
    

    const emit = defineEmits<{
		selectedDateEmit: [value: Date |  null]
	}>();

    const selectedDate = ref<Date>(props.modelValue);
    const maxDateLimit = ref<Date | null | string>(null);
    const minDateLimit = ref<Date | null | string >(null);
    
    if (props.inputId.includes("expirydateofpassport")) {
        // allow future dates
        determineDateRange(CRMHtmlType.FutureDate.toString());
    } 
    else if (props.inputId.includes("graduationdate")) {
        // no limitation
        determineDateRange(undefined);
    }
    else {
        // past dates until today
        determineDateRange(props.validRange);
    }

    // watch(() => props.modelValue, (newValue, oldValue) => {
    watch(() => selectedDate, (newValue, oldValue) => {
        selectedDate.value = newValue;
        
    });

    watchEffect(() => {
        if(props.modelValue != null || props.modelValue !== '' ) {
            
           selectedDate.value = props.modelValue;
        }
    });

    const handleSetDate = (chosenDate: Date) => {
        selectedDate.value = chosenDate;
        if (typeof (props.validator) !== 'undefined' && props.validator != null) {
            let dateValue: Date | null = (chosenDate != null) ? chosenDate : null;
            props.validator.setValidationRuleSourceValue(props.validatorRuleId, dateValue);
            let resultList = props.validator.validateRule(props.validatorRuleId);

            ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
        }
        emit("selectedDateEmit", selectedDate.value);
    }

    const handleClose = () => {
        if (typeof (props.validator) !== 'undefined' && props.validator != null) {

            props.validator.setValidationRuleSourceValue(props.validatorRuleId, selectedDate.value);
            let resultList = props.validator.validateRule(props.validatorRuleId);

            ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
        }
    }

    function determineDateRange(validRange: string | undefined) {
        if (typeof(validRange) !== 'undefined'){
            if (validRange == CRMHtmlType.PastDate.toString()) {
                maxDateLimit.value = new Date();
                minDateLimit.value = null; 
            }
            else if (validRange == CRMHtmlType.FutureDate.toString()) {
                maxDateLimit.value = null ;
                minDateLimit.value = new Date(); 
            }
        }
    }

    function ApplyValidate(isInvalid: boolean, validationResultList: Array<IValidationResult>) {
        const inputElement = document.getElementById(`dp-input-${props.inputId}`) as HTMLElement;
        const errorElement = document.getElementById(`${props.inputId}-error`) as HTMLElement;

        if (isInvalid) {
            inputElement.classList.add("has-error");
        }
        else {
            inputElement.classList.remove("has-error");
        }

        let errorString = '';
        if (typeof (props.validator) !== 'undefined' && props.validator != null) {
            errorString = props.validator.getValidationResultString(props.validatorRuleId);
        }
        errorElement.innerText = errorString;
    }
</script>

<!-- theming: https://vue3datepicker.com/customization/theming/ -->
<style>
    .dp__menu {
        height: 361px !important;
    }
    .dp__theme_light {
        --dp-primary-color: #002C77 !important;
        --dp-highlight-color: #002C77 !important;
        --dp-text-color: #293748 !important;
        --dp-danger-color: #CF0000  !important;
    }

    .dp__action_buttons .dp__action_button.dp__action_select {
        background-color: #002C77 !important;
    }
    .dp__action_buttons .dp__action_button.dp__action_select:hover {
        background-color: #7D293E !important;
    }

    .dp__menu_inner {
        padding-bottom: 0px !important;
    }

    .dp__calendar .dp__calendar {
        width: 276px;
        min-height: 220px !important;
        max-height: 242px !important;
        height: 100% !important;
    }

    .dp__action_row {
        padding-top: 0px !important;
    }

</style>